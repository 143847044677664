@import "_minireset";

body {
    font-size: 16px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    color: midnightblue;
    background: #f5f5f5;
}
.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

body#prayer_for_the_day {
    background: url("backgrounds/psychodelic_flower.jpg") center center repeat
        transparent;
}
body#encouragers_journal {
    background: url("backgrounds/pink_fabric2.gif") center center repeat
        transparent;
}
body#promises_to_claim {
    background: url("backgrounds/purple_paper.gif") center center repeat
        transparent;
}
body#poetry,
body#poetry_page_2 {
    background: url("backgrounds/mauve_marble.gif") center center repeat
        transparent;
}
body#links {
    background: url("backgrounds/gr_marble.gif") center center repeat
        transparent;
}
body#scripture_to_minister {
    background: url("backgrounds/lght_gr_paper.gif") center center repeat
        transparent;
}

.container {
    display: flex;
    width: 100%;
    justify-content: flex-start;

}
.content {
    padding: 10px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
footer {
    display: block;
    text-align: center;
    @extend .cursive;
}

h1 {
    font-size: 36px;
    font-weight: bold;
}

p,
ol {
    margin-bottom: 16px;
}

.font-size-4 {
    font-size: 24px;
}
.font-size-5 {
    font-size: 32px;
    line-height: 1.75;
}

.cursive {
    font-family: "Indie Flower", cursive;
}

.text-center {
    text-align: center !important;
}

.blockquote {
    margin-left: 5%;
}

nav {
    padding: 20px 30px 20px 20px;
    flex-basis: 230px;
    flex-shrink: 0;
}

.navigation-list {
    list-style: none;
    li {
        display: block;
        position: relative;
        a {
            text-decoration: none;
            font-family: Tangerine, cursive;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 0.9em;
            background-color: #ffbdff;
            border-radius: 50%;
            display: flex;
            text-align: center;
            padding: 12px;
            border: 2px solid #ff94ff;
            margin: 0 0 1rem;
            color: navy;
            width: 180px;
            height: 74px;
            justify-content: center;
            align-items: center;
            &:hover {
                &::after {
                    color: orange;
                    display: block;
                    position: absolute;
                    right: -26px;
                    top: 20px;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f004";
                }
            }
        }
        &:nth-child(2) {
            a:hover::after {
                color: yellow;
            }
        }
        &:nth-child(3) {
            a:hover::after {
                color: lime;
            }
        }
        &:nth-child(4) {
            a:hover::after {
                color: purple;
            }
        }
        &:nth-child(5) {
            a:hover::after {
                color: turquoise;
            }
        }
        &:nth-child(6) {
            a:hover::after {
                color: hotpink;
            }
        }
        &:nth-child(7) {
            a:hover::after {
                color: indigo;
            }
        }
        &:nth-child(8) {
            a:hover::after {
                color: red;
            }
        }
        &:nth-child(9) {
            a:hover::after {
                color: green;
            }
        }
        &:nth-child(10) {
            a:hover::after {
                color: tomato;
            }
        }
        &:nth-child(11) {
            a:hover::after {
                color: goldenrod;
            }
        }
        &:nth-child(12) {
            a:hover::after {
                color: maroon;
            }
        }
    }
}
